import * as crypto from 'crypto-js';

import { Injectable } from '@angular/core';
import { Contribuyente, RegisterUsuarioWeb,Mensaje } from '@modelos/auth/user';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { apiMainService } from '@servicios/api.main.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})


export class AuthenticationService extends apiMainService {

  public contribuyente: Contribuyente = new Contribuyente();
  public mensaje: Mensaje = new Mensaje();
  public menuSelected: string = '' //Título del menú seleccionado
  public menuIconSelected: string = '' //Icono del menú seleccionado


  constructor(
    public http: HttpClient,
    private router: Router) 
  {      
      super(http);

      //this.contribuyente.id_ciudadano = 2127 //temporal
  }

  registerUsuarioWeb$(register: RegisterUsuarioWeb): Observable<RegisterUsuarioWeb> {

    return this.http.post<RegisterUsuarioWeb>(this.url_api + '/registerusuarioweb/', register, { headers: this.httpOptions.headers });

  }

  //Validamos si el contribuyente está dentro del sistema
  isLogin(): boolean {
    return this.contribuyente && this.contribuyente.token != '' && this.contribuyente.activo  && this.contribuyente.isLogin 
  }

  
  //Solo para la opción  de cambio de clave tendrá acceso en el primer acceso
  isRegister() : boolean{
    return this.contribuyente && this.contribuyente.token != '' && this.contribuyente.activo && !this.contribuyente.isLogin 

  }

  
  //Inicio de sesión
  login(id_contribuyente: string, password: string): Observable<Contribuyente> {
    
    // this.contribuyente.email = codigo
    // this.contribuyente.password = crypto.SHA512 (codigo + password).toString();

    let login = {
      id_contribuyente: id_contribuyente,
      clave: crypto.SHA512 (password).toString()
    }
    

    return this.http.post<Contribuyente>(this.url_api + '/loginoficinavirtual/', login, { headers: this.httpOptions.headers });
  }

  logout() {
    // remove user from local storage to log user out
    // localStorage.removeItem('currentUser');
    this.contribuyente=null
    this.contribuyente  = new Contribuyente();

    //borramos los datos
    //this.user = new sgUsuario;

    // this.router.navigate(['/auth/login']);
  }

  //Recuperamos contraseña, se envía una nueva contraseña a su correo.
  forgotPassword(id_contribuyente: number, email: string): Observable<boolean> {
    let datos = {
      id_contribuyente: id_contribuyente,
      email: email
    }

    //Se generar una contraseña nueva a email.
    return this.http.post<boolean>(this.url_api + '/forgotoficinavirtual/', datos, { headers: this.httpOptions.headers });


  }

  resetPassword(clave: string): Observable<boolean> {

    clave = crypto.SHA512(clave).toString();

    let datos = {
      id_contribuyente: this.contribuyente.id_contribuyente,
      clave: clave
    }

    //Cambiar la contraseña ..req.headers.authorization => TOKEN
    return this.http.post<boolean>(this.url_api + '/resetoficinavirtual/', datos, { headers: this.httpOptions.headers });


  }

  //Cambiar clave primer Acceso
  cambiarClavePrimerAcceso$( email :string ,celular :string ,claveNueva : string )
  {

    this.contribuyente.clave=crypto.SHA512 (claveNueva).toString()
    this.contribuyente.cambiar_clave=false
    this.contribuyente.email=email
    this.contribuyente.num_celular = celular;
    this.contribuyente.sesion_iniciada=true    

    return this.http.put<Contribuyente>(this.url_api + '/loginoficinavirtual/', this.contribuyente, { headers: this.httpOptions.headers });

  }


}
