<main style="width: 100%" class="page-layout blank p-24" fusePerfectScrollbar>
    <!-- BOARDS -->
    <div id="boards" fxLayout="column" fxLayoutAlign="start center" fxFlex fusePerfectScrollbar style="width: 100%">


        <!-- HEADER -->
        <div id="profile">

            <div class="header p-24 bg-primary" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="space-between top">

                <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
                    fxLayoutAlign.gt-sm="start center">
                    <img class="profile-image avatar huge" src="assets/images/logos/logo.png">
                    <div class="name">
                        Menú Principal
                    </div>
                </div>

            </div>
        </div>
        <!--/ HEADER -->

        <!-- BOARD LIST -->

        <div id="academy-courses" class="page-layout simple">

            <div class="content p-24">
          
              <div fxLayout="column" fxLayoutAlign="center">
          
          
          
            <div class="courses" fxLayout="row wrap" fxLayoutAlign="center" [@animateStagger]="{value:'50'}">
              
              <div  class="course" 
                    *ngFor="let item of boards" 
                    fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33"  
                    [@animate]="{value:'*',params:{y:'100%'}}">
          
                                <div class="course-content" fxLayout="column" fxFlex="1 1 auto">
          
                                    <div class="bg-primary header" fxLayout="row" fxLayoutAlign="center center">
          
                                        <div class="category" fxFlex>
                                            {{item.name | uppercase }}
                                        </div>
          
                                        <div class="length" fxLayout="row" fxLayoutAlign="center center">
                                            <mat-icon class="length-icon s-40">{{item.icono}}</mat-icon>
                                            <!-- <div class="min">{{item.id}} min</div> -->
                                        </div>
          
                                    </div>
          
                                    <div class="content" fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1 auto">                                        
                                        <div class="updated">{{item.descripcion}}</div>
                                    </div>
          
                                    <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                                        <button
                                        (click)="fn_navegacion(item)"
                                        style="margin-left: 25px;"
                                        mat-button
                                        color="accent">
                                            INGRESAR
                                        </button>
                                        
                                        <!-- <button mat-button color="accent"  (click)="fn_navegacion(item)">
                                            INGRESAR
                                        </button> -->
                                    </div>
          
                                </div>
          
                </div>
          
              </div>
          
              </div>
            </div>
        </div>


        <!-- <div class="board-list" fxFlex="0 0 auto" fxLayout="row wrap" fxLayoutAlign="center center"
            [@animateStagger]="{value:'50'}">

            NEW BOARD BUTTON
            <div class="  fuse-navy-I100 board-list-item add-new-board" fxLayout="column" fxLayoutAlign="center center"
                (click)="newBoard()" [@animate]="{value:'*',params:{y:'100%'}}">
                <mat-icon class="s-56">add_circle</mat-icon>
                <div class="board-name">Nueva Solicitud</div>
            </div>
            / NEW BOARD BUTTON

            BOARD
            <div class=" fuse-navy-I200 board-list-item" (click)="fn_navegacion(board)" style="width: 10px" fxLayout="column"
                fxLayoutAlign="center center" [@animate]="{value:'*',params:{y:'100%'}}" 
                *ngFor="let board of boards">


                <mat-icon class="s-56">{{board.icono}}</mat-icon>
                <div class="board-name">{{board.name}}</div>
            </div>
            / BOARD



        </div> -->
        <!-- / BOARD LIST -->



    </div>

    <!-- / BOARDS -->
</main>