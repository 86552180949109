import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; 

import { map, catchError, tap } from 'rxjs/operators'; 


@Injectable()

// tslint:disable-next-line: class-name
export class apiMainService {
  // tslint:disable-next-line: variable-name
  public url_api = 'https://190.116.36.140:8080'; 
  public httpOptions = { 
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      // 'X-Requested-With' : 'XMLHttpRequest'

      
    }) 
    }; 
      
  constructor(public http: HttpClient) { }

}
