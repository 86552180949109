import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector   : 'error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls  : ['./error-dialog.component.scss']
})
export class ErrorDialogComponent
{
    public confirmMessage: string;
    public titleMessage : string

    /**
     * Constructor
     *
     * @param {MatDialogRef<ErrorDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<ErrorDialogComponent>
    )
    {
    }

}
