import { Component, OnInit } from '@angular/core';
import { FileService } from './file.service';

import { FileSelectDirective, FileUploader} from 'ng2-file-upload';
import {saveAs} from 'file-saver';

const uri = 'http://192.168.1.111:2800/fileupload';




//npm install @types/file-saver --save-dev
//multer

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers:[FileService]

})
export class HomeComponent {

  uploader:FileUploader = new FileUploader({url:uri});

  attachmentList:any = [];

  constructor(private _fileService:FileService){

      this.uploader.onCompleteItem = (item:any, response:any , status:any, headers:any) => {
          this.attachmentList.push(JSON.parse(response));
      }
  }

  download(index){
      var filename = this.attachmentList[index].uploadname;

      this._fileService.downloadFile(filename)
      .subscribe(
          data => saveAs(data, filename),
          error => console.error(error)
      );
  }
}