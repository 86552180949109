<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img width="350px" src="assets/images/logos/logo-full.png">
                <!-- <img src="assets/images/logos/fuse.svg"> -->                
                
                
            </div>

            <div class="title"><mat-icon class="mat-accent s-28">security</mat-icon> RECUPERAR SU CLAVE</div>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Código de Contribuyente</mat-label>
                    <input maxlength="7" matInput formControlName="identidad">
                    <mat-icon matSuffix class="secondary-text">account_balance</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('identidad').hasError('required') ">
                        Código  es Requerido
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('identidad').hasError('minlength') || forgotPasswordForm.get('identidad').hasError('maxlength')">
                        Por favor ingrese Código válida.
                    </mat-error>
                </mat-form-field>


                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input maxlength="38" matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        Email es Requerido
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        Por favor ingrese un e-mail válido
                    </mat-error>
                </mat-form-field>

                <button (click)="forgot()" mat-raised-button class="submit-button" color="accent"
                        aria-label="SEND RESET LINK" [disabled]="forgotPasswordForm.invalid || procesando">
                    ENVIAR
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/auth/login'">Regresar a Inicio de Sesión</a>
            </div>

        </div>

    </div>

</div>
