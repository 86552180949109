import { createAction, props } from '@ngrx/store';

export const increment = createAction('[Sesion] Descripsiòn Increment');
export const decrement = createAction('[Sesion] Descripsiòn Decrement');

export const multiplicar = createAction(
    '[Sesion] Descripsiòn Multiplicar',
    props<{valor : number}>()
    
    );

export const reset = createAction('[Sesion] Reset');