import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { DoneDialogComponent } from '@componentes/dialog/done-dialog/done-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';

@NgModule({
    declarations: [
        DoneDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule
    ],
    entryComponents: [
        DoneDialogComponent
    ],
})
export class DoneDialogModule
{
}
