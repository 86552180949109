

export class Contribuyente {

	id_contribuyente		: number;		
	contribuyente			: string;
	doc_identidad			: string;
	num_doc_identidad		: string;
	tipo_persona			: string;
	subtipo_persona			: string;
	email                   : string;	
	num_celular				: string;
	imagen			        : string;
	clave			        : string;
	cambiar_clave			: boolean;
	activo					: boolean;
	fecha_last_cambio		: Date;	
	sesion_iniciada			: boolean;
	direccion_completa 		: string;
	sector					: string;
	lugar					: string;
	via 					: string;
	numero 					: string;
	edificio 				: string;
	piso 					: string;
	numero_manzana 			: string;
	numero_interior 		: string;
	
	isLogin					: boolean;
	token                   : string;
	

}

export class RegisterUsuarioWeb {
	id_usuario_web 		: number;	
	persona_id 			:number;
	nombres_apellidos 	: string;
	celular 			: string;
	usuario				: string;
	dni					: string;
	correo 				: string;
	clave				: string;
	estado 				: string;
	activo 				: string;
	fecha_registro 		: Date;
}

export class Mensaje{
	tituto 		: string;
	iconTitulo		: string;
	subtitulo 		: string;
	data1 			: string;
	data2 			: string;
	data3 			: string;
	data4 			: string;
	footer 			: string;


}

