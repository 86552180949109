import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseMaterialColorPickerModule } from '@fuse/components';

import {HomeComponent} from '@componentes/home/home.component'


// const routes: Routes = [
//     {
//         path     : 'home',
//         component: HomeComponent,
//     }
    
  
// ];

@NgModule({
    declarations   : [
        HomeComponent,
    ],
    imports        : [
        // RouterModule.forChild(routes),
        FuseSharedModule,
        // FuseConfirmDialogModule,
        FuseMaterialColorPickerModule
           
    ],
    providers      : [],
    // entryComponents: [ScrumboardCardDialogComponent]

    exports : [HomeComponent]
})
export class HomeModule
{
}
