<div id="reset-password" fxLayout="column">

    <div id="reset-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="reset-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <!-- <img src="assets/images/logos/fuse.svg"> -->
                <img width="250px" src="assets/images/logos/logo-full.png">
                <!-- <mat-icon class="mat-accent s-96">autorenew</mat-icon> -->
            </div>

            <div class="title">CAMBIA TU CONTRASEÑA</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('required')">
                        Email es requerido
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.get('email').hasError('email')">
                        Por favor ingrese un correo válido                     
                    </mat-error>
                </mat-form-field>




                <mat-form-field appearance="outline">
                    <mat-label>Ingrese Clave (6 dígitos)</mat-label>
                    <input [type]="hide2 ? 'password' : 'text'" maxlength=6 matInput type="password" formControlName="password">
                    
                    <!-- <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon> -->
                    <mat-icon style="cursor: pointer;" matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>

                    <mat-error>
                        Contraseña es requerida.
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Clave (Confirmación)</mat-label>
                    
                    <input  [type]="hide1 ? 'password' : 'text'" maxlength=6 matInput type="password" formControlName="passwordConfirm">
                    
                    <mat-icon style="cursor: pointer;" matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <!-- <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon> -->

                    <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                        Contraseña de resetPasswordForm es requerida.
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                    resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Las Contraseñas deben ser iguales.
                    </mat-error>
                </mat-form-field>




                <!-- <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Contraseña es requerida.
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password (Confirm)</mat-label>
                    <input matInput type="password" formControlName="passwordConfirm">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('passwordConfirm').hasError('required')">
                        Contraseña de confirmación es requerida.
                    </mat-error>
                    <mat-error *ngIf="!resetPasswordForm.get('passwordConfirm').hasError('required') &&
                                       resetPasswordForm.get('passwordConfirm').hasError('passwordsNotMatching')">
                        Las Contraseñas deben ser iguales.
                    </mat-error>
                </mat-form-field> -->

                <button (click)="resetPassword()" mat-raised-button class="submit-button" color="accent"
                        aria-label="RESET MY PASSWORD" [disabled]="resetPasswordForm.invalid  || !this.authenticationService.contribuyente.email || this.authenticationService.contribuyente.email==''  ">
                    CAmbiar Contraseña
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/menuboard'">Cancelar</a>
            </div>            
            

        </div>

    </div>

</div>
