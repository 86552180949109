import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeActivo'  
})

export class ActivoPipe implements PipeTransform {

  transform(value: any): string {    
    
    if (value ==='true'  || value=='1')
      return 'Si'
    if (value ==='false' || value=='0')
      return 'No'

    return value

  }

}