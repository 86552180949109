<div  id="login" fxLayout="column">

    <div style="margin-left: 5%;" id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center left">
        <div class="containerselect">

           
            

        <div class="itemcontainerselect" style="background-color: rgba(255, 255, 255, 1)" id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            

            <!-- <div class="logo">                
                <mat-icon class="mat-accent s-96">fingerprint</mat-icon>                
            </div> -->

            <div class="logo">
                <img src="assets/images/logos/logo.png">
            </div>

            <div class="title"> INICIO SESIÓN </div>
            <!-- <div class="title"> 
                <mat-icon class="mat-accent s-32">fingerprint</mat-icon>
            </div> -->


            <form name="loginForm" [formGroup]="loginForm" novalidate (ngSubmit)="login()">

                <mat-form-field appearance="outline">
                    <mat-label>Código de Contribuyente</mat-label>
                    <input maxlength=7 matInput formControlName="codigo"  type="text" autocomplete="off" onlyNumber  >
                    <mat-icon matSuffix class="secondary-text">account_box</mat-icon>
                    <mat-error *ngIf="loginForm.get('codigo').hasError('required')">
                        Código es Requerido
                    </mat-error>
                    <mat-error
                        *ngIf="!loginForm.get('codigo').hasError('required') &&
                                loginForm.get('codigo').hasError('codigo')">
                        POr favor ingrese un Código vàlido.                        
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Contraseña</mat-label>
                    <input maxlength=6 [type]="hide ? 'password' : 'text'" matInput type="password" formControlName="password">
                    <mat-icon style="cursor: pointer;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <!-- <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon> -->
                    <mat-error>
                        Contraseña es Requerido
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <!-- <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Recordarme Contraseña
                    </mat-checkbox> -->

                    <a class="forgot-password" [routerLink]="'/auth/forgot'">
                        ¿Olvidó su Contraseña?
                    </a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                        [disabled]="loginForm.invalid || inactivarBoton">
                    Iniciar Sesión
                </button>

                
                <!-- <button mat-raised-button (click)="register()" color="warn" class="submit-button" aria-label="LOG IN">
                    REGÍSTRATE                    
                </button> -->


            </form>

            <!-- <div class="separator">
                <span class="text">OR</span>
            </div>

            <button mat-raised-button class="google">
                Log in with Google
            </button>

            <button mat-raised-button class="facebook">
                Log in with Facebook
            </button>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/pages/auth/register'">Create an account</a>
            </div> -->

        </div>

        <div style="margin-top: 50px;" class="itemcontainerselect">
            <div style="color: white;width: 400px;padding: 20px; background-color: rgba(255, 255, 255, 0.05)">

                <!-- <div class="logo">
                    <img width="30PX" src="assets/images/logos/logo.png">
                </div> -->
                
                <h1>
                    <img width="25PX" src="assets/images/logos/logo.png"> SAT CAJAMARCA</h1>
                <H3>OFICINA VIRTUAL</H3>

                <p style="text-align: justify;" >                 
                    SAT CAJAMARCA, Pone a disposición del ciudadano Cajamarquino su plataforma virtual donde podrá consultar y gestioanr su información tributaria.
                </p>
    
            </div>


            <div style="color: white;width: 400px;padding: 20px; background-color: rgba(0, 0, 0, 0.7)">

                

                <p style="text-align: justify;" >                 

                    <mat-icon color="warn" >notification_important</mat-icon>
                    En caso no haya recibido su Carta conteniendo su Clave SEL, por favor comunicarse a los siguientes números móviles para obtenerla de manera inmediata: 971 859 860 - 990 759 828 - 944 745 748 - 949 268 894 - 944 080 498
                </p>
    
            </div>



        </div>

        </div>

    </div>

</div>
