<div class="dialog-content-wrapper">

    <mat-toolbar matDialogTitle class="mat-warn m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">

            <div class="parent">

                <div class="div1">

                    <mat-icon class="logo-icon s-32 mr-16">
                        error
                    </mat-icon>

                    <span class="title dialog-title">{{titleMessage}}</span>

                </div>

                <div class="div2">
                    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>

            </div>

        </mat-toolbar-row>

    </mat-toolbar>

    <div class="contenido">
        <!-- <h1 matDialogTitle>Confirm</h1> -->
        <div class="elemento" mat-dialog-content>{{confirmMessage}}</div>
        <div class="elemento" mat-dialog-actions >
            <button mat-raised-button (click)="dialogRef.close()" color="accent">Aceptar</button>
        </div>
    </div>

</div>