import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthenticationService } from '@servicios/common/auth/authentication.service'

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class LoginGuardRegister implements CanActivate, CanLoad {

  constructor(private router: Router, private authenticationService: AuthenticationService) { }


  canActivate(): boolean {

    // return true

    let sesion_iniciada = this.authenticationService.isRegister()

    //Validamos si ha iniciado sesiòn
    if (!sesion_iniciada || !sesion_iniciada)
      this.router.navigate(['auth/login']);

    return sesion_iniciada

  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): boolean {

    //return true

    let sesion_iniciada = this.authenticationService.isRegister()

    //Validamos si ha iniciado sesiòn
    if (!sesion_iniciada || !sesion_iniciada)
      this.router.navigate(['auth/login']);

    return sesion_iniciada
  }

}