import { NgModule } from '@angular/core';
import {LoginModule} from '@componentes/auth/login/login.module'
import {ForgotPasswordModule} from '@componentes/auth/forgot-password/forgot-password.module'
import {ResetPasswordModule} from '@componentes/auth/reset-password/reset-password.module'

// import {RegisterModule} from '@componentes/auth/register/register.module'

import {MailConfirmModule} from '@componentes/auth/mail-confirm/mail-confirm.module'


@NgModule({

  imports: [
    LoginModule,
    ForgotPasswordModule,  
    ResetPasswordModule ,
    MailConfirmModule,
    // RegisterModule

    // RouterModule.forRoot(routes)
  ],
  exports: [],
  providers: []
})

export class AuthModule { }

