import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { AuthenticationService } from '@servicios/common/auth/authentication.service'
import { Router } from '@angular/router';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    hide1 =true
    hide2  =true
    resetPasswordForm: FormGroup;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,

        public authenticationService: AuthenticationService,
        private router : Router
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.resetPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(64)]],
            passwordConfirm: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(64), confirmPasswordValidator]]
        });

        this.resetPasswordForm.get('email').setValue(this.authenticationService.contribuyente.email || '')
        this.resetPasswordForm.get('email').disable()

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }


    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    resetPassword() {

        let email: string = this.resetPasswordForm.get('email').value
        let password: string = this.resetPasswordForm.get('password').value


        this.authenticationService.resetPassword(password).subscribe(
            (data) => {
                if (data) {
                    //Se ha cambiado correctamente  el password                    
                    this.router.navigate(['/auth/mail-confirm/']);
                    this.authenticationService.logout();

                    this.authenticationService.mensaje.tituto='CAMBIO DE CLAVE DE ACCESO SEL'
                    this.authenticationService.mensaje.iconTitulo='mail'
                    this.authenticationService.mensaje.data1='Ha cambiado su clave de acceso.'
                    this.authenticationService.mensaje.data3='Hemos enviado un correo de confirmación al e-mail:'
                    this.authenticationService.mensaje.data4=email

                    this.authenticationService.mensaje.footer='Su clave es la única forma de acceder a nuestros servicios, no la comparta.'

                    // Swal.fire({
                    //     type: 'success',
                    //     position : "top",
                    //     title: 'Se ha cambiado la Contraseña Exitosamente.',
                    //     showConfirmButton: true,                        
                    //   })

                }
                else {
                    //Ha ocurrido un error
                    alert('Ha ocurrido un error.')

                }

            }
        )
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
