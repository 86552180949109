import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { AuthenticationService } from '@servicios/common/auth/authentication.service'
import { Router } from '@angular/router';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    procesando: boolean=false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        public authenticationService: AuthenticationService,
        private router : Router
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            identidad: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(32)]]
        });
    }

    forgot() {
        this.procesando = true


        let identidad: number = this.forgotPasswordForm.get('identidad').value
        let email: string = this.forgotPasswordForm.get('email').value

        if ( email=='' || identidad.toString() =='')
        {
            alert('La información ingresada no es correcta.')
            return
        }


        this.authenticationService.forgotPassword(identidad,email).subscribe(
            
            (data)=>{

                if (data)
                {
                    //Se ha generado correctamente la nueva contraseña.
                    this.router.navigate(['/auth/mail-confirm']);

                    this.authenticationService.mensaje.tituto='CAMBIO DE CLAVE DE ACCESO SEL'
                    this.authenticationService.mensaje.iconTitulo='mail'
                    this.authenticationService.mensaje.data1='Ha solicitado rstablecer su clave de acceso'
                    this.authenticationService.mensaje.data2='Hemos enviado su nueva CLAVE al correo electrónico '                    
                    this.authenticationService.mensaje.data3=email

                    this.authenticationService.mensaje.footer='Su clave es la única forma de acceder a nuestros servicios, no la comparta.'


                }
                else
                {
                    alert('Los datos son incorrectos no se ha podido generar la contraseña')
                }
                


            },

            (err)=>
            {
                console.log(err)
            },

            ()=>{
                this.procesando = false

            }


        )
    }
}
