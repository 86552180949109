import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'Home',
                title    : 'Declaración Juarada Predial',                
                type     : 'item',
                icon     : 'ballot',
                url      : '/gestion/jspredial',
            },
            {
                id       : 'EstadoCuenta',
                title    : 'Estado Cuenta',                
                type     : 'item',
                icon     : 'email',
                url      : '/gestion/estadocuenta'
            },
            {
                id       : 'Recibos',
                title    : 'Recibos de Pago',                
                type     : 'item',
                icon     : 'person',
                url      : '/gestion/pagos',                
            },

            // {
            //     id       : 'Producto',
            //     title    : 'GEstión Producto',                
            //     type     : 'item',
            //     icon     : 'ballot',
            //     url      : '/menuboard',
            // },

            // {
            //     id       : 'Producto',
            //     title    : 'Regsitro Producto',        
            //     type     : 'item',
            //     icon     : 'ballot',
            //     url      : '/producto/nuevo',
            // },
            

          
            

        ]
    }
];
