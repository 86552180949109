import { Injectable } from '@angular/core';


import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@componentes/dialog/error-dialog/error-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';


// enum VerticalPosition1 {"top","bottom"}
//export type VerticalPosition = "top" | "bottom" 
type VerticalPosition = "top" | "bottom" 
type HorizontalPosition = "right" | "left"  | "center" | "end" | "start"


@Injectable({
  providedIn: 'root'
})



export class MessagesService {

  errorDialogRef: MatDialogRef<ErrorDialogComponent>;

  constructor(
    private mensajeBar: MatSnackBar,
    private _matDialog: MatDialog    ) { }

  showMsgError(title: string, message: string) {

    this.errorDialogRef = this._matDialog.open(ErrorDialogComponent, {
      disableClose: false,
      panelClass: 'error-dialog'
    });

    this.errorDialogRef.componentInstance.titleMessage = title
    this.errorDialogRef.componentInstance.confirmMessage = message

  }

  showSnackBar(message: string, action: string,verticalPosition : VerticalPosition, horizontalPosition : HorizontalPosition ,duracion: number) {


    this.mensajeBar.open(message, action, {
      duration: duracion,
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition,      

    });
  }




}
