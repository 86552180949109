import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../auth/authentication.service';
import { MessagesService } from '../messages/messages.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private msg: MessagesService,
        private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            catchError(error => {

                let titleMessage = 'Error de Conexiòn';
                let errorMessage = '';

                //405, error del servidor node, cuando no se ha seguido las reglas de validaciòn definidads por la base de datos. Como Unique, etc.
                if (error.status == '405') {
                    titleMessage='Error de Validación'
                    errorMessage='La información no es válida para ser registrarda. Por favor verifique los datos y vuelva a intentarlo.'

                }
                else {
                    if (error instanceof ErrorEvent) {
                        // client-side error
                        // errorMessage = `Client-side error: ${error.error.message}`;
                        errorMessage = 'Se ha perdido la conexiòn a Internet. Por favor verifique e inténtelo nuevamente.'
                    } else {
                        // backend error
                        // errorMessage = `Server-side error: ${error.status} ${error.message}`;
                        errorMessage = 'Se ha producido un error con el servidor. Por favor inténtelo nuevamente.'
                    }
                }

                // aquí podrías agregar código que muestre el error en alguna parte fija de la pantalla.

                //   this.msg.showMsgError('Error Servidor',errorMessage);                
                this.msg.showMsgError(titleMessage,errorMessage);


                // console.log(error.error)
                // console.log(error.error.message)


                return throwError(errorMessage);
            })
        );
    }

    // return next.handle(request).pipe(

    //     catchError(err => {

    //         console.warn('error...', err)

    //         switch (err.status) {
    //             case 401:
    //                 this.authenticationService.logout();
    //                 break;

    //             default:
    //                 break;
    //         }


    //         // if (err.status === 401) {
    //         //     // auto logout if 401 response returned from api
    //         //     this.authenticationService.logout();
    //         //     // location.reload(true);
    //         // }

    //         const error = err.error.message || err.statusText;

    //         console.log('INTI.', error)

    //         return throwError(error);
    //     }))
}
