import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';

import { LoginComponent } from './login.component';

const routes = [
    {
        path     : 'auth/login',
        component: LoginComponent
    }
    ,
    {
        path: '',
        redirectTo: '/auth/login',
        pathMatch : 'full'
    }
    // {
    //     path: '**',
    //     redirectTo: '/auth/login' //PageNotFoundComponent
    // }
];

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports     : [
        
        RouterModule.forChild(routes,),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,

        FuseSharedModule
    ]
})
export class LoginModule
{
}
