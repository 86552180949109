//See xporta alñ módulo en  '@fuse/shared.module.ts'

import { NgModule }      from '@angular/core';
import { ActivoPipe }    from './activo.pipe';

 @NgModule({
     
    declarations   :   [
         ActivoPipe
        ],
    imports        :   [],
    exports        :   [
         ActivoPipe
    ]
     
 })

 export class PipeModule {

   static forRoot() {
      return {
          ngModule: PipeModule,
          providers: [ActivoPipe],
      };
   }
 } 
