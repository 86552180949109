import { Injectable, ɵConsole } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '@servicios/common/auth/authentication.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //Solo apra desarrollo
        // request = request.clone({
        //     setHeaders: {
        //         Authorization: '',
                
        //     }
        // });  
        // return next.handle(request);



        // Para producciòn
        // add authorization header with jwt token if available
        

        if (this.authenticationService.contribuyente && this.authenticationService.contribuyente.token) {

            request = request.clone({
                setHeaders: {
                    Authorization   : `${this.authenticationService.contribuyente.token}`                    
                }
            });

        }

        return next.handle(request);
    }
}