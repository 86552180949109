import { createReducer, on } from '@ngrx/store';
import * as acciones from './sesion.action';
import { Sesion } from 'app/interfaces/sesion/sesion';
 
export const initialState : Sesion = {contador : 2,valores : 'nuloss'};

 
const incremento = (state : Sesion)=> {    
    state.contador+=2;    
    state.valores='SUma'
    return state;
}

const decremento = (state : Sesion )=> {
    state.contador-=1;    
    return state;
}

const reset = (state : Sesion )=> {
    state.contador = 0;    
    return state;
}

const multiplicar = (state : Sesion, {valor} )=> {
    state.contador *= valor;        
    return state;
}


const _counterReducer = createReducer(initialState,
  on(acciones.increment, incremento),
  on(acciones.decrement, decremento ),
  on(acciones.reset, reset),
  on(acciones.multiplicar, multiplicar ),
);
 
export function counterReducer(state : Sesion, action) {
  return _counterReducer(state, action);
}
