import {FileUploadModule } from 'ng2-file-upload';


import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';



//ngRx
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment'; // Angular CLI environment

//SATC
import {ComponentesRoutingModule} from '@componentes/componentes-routing-module'
import { NgxCaptchaModule } from 'ngx-captcha';


import { HashLocationStrategy, LocationStrategy } from '@angular/common';

//FIN DE SATC


//--INTI--

// temporal


//Interceptores
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from '@servicios/common/Interceptor';


import { SampleModule } from 'app/main/sample/sample.module';
import { HomeModule } from '@componentes/home/home.module';

import { AuthModule } from '@componentes/auth/auth-module'

import { MenuBoardModule } from '@componentes/menu-board/menu-board.module'

import { LogisticaRoutingModule } from '@componentes/logistica/logistica-routing-module'

//Pipe
import { PipeModule } from './pipes/pipe.module';
//

//ngRx
import { counterReducer } from 'app/redux/sesion/sesion.reducer'
//

import {ErrorDialogModule} from '@componentes/dialog/error-dialog/error-dialog.module'
import {DoneDialogModule} from '@componentes/dialog/done-dialog/done-dialog.module'

////--Fin INTI--


import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { DirectivesModule } from './directives/directives.module';



@NgModule({
    declarations: [
        AppComponent,
        
        
    ],
    imports: [
        //SATC
        DirectivesModule,
        NgxCaptchaModule,
        ComponentesRoutingModule,
        //FIn DE SATC
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        TranslateModule.forRoot(),

        FileUploadModule,
        


        // RouterModule.forRoot(appRoutes), //Solo para Sample.


        PipeModule.forRoot(), //Se despliega dede @fuse/shared.module.ts, pero se realiza esta acción par apoder usarlo en loslas vitsas (html)

        //INTI
        //temporal
        
        
        //finde temporal


        HomeModule,
        // LoginModule,
        AuthModule,

        MenuBoardModule,
        LogisticaRoutingModule,
        ErrorDialogModule,
        DoneDialogModule,

        //NgRX
        StoreModule.forRoot({ contador: counterReducer }),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),               

        //Fin INTI

        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        //Interceptores
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        //Agrega un # a las rutas para poder recargar en ´producción
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],

   exports:[DirectivesModule]

})
export class AppModule {
}
