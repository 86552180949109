<div id="mail-confirm" fxLayout="column">

    <div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="mail-confirm-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img width="250px" src="assets/images/logos/logo-full.png">
            </div>

            

            <mat-card class="example-card">

                <mat-card-header>
                  <div mat-card-avatar class="example-header-image">
                    <mat-icon class="mat-accent s-32">{{this.authenticationService.mensaje.iconTitulo}}</mat-icon>
                    
                  </div>

                  <mat-card-title>{{this.authenticationService.mensaje.tituto}}</mat-card-title>
                  <mat-card-subtitle>{{this.authenticationService.mensaje.subtitulo}}</mat-card-subtitle>
                </mat-card-header>
                
                
                
                <mat-card-content>

                    <h2>{{this.authenticationService.mensaje.data1}}</h2>
                    <h3>{{this.authenticationService.mensaje.data2}}</h3>
                    <h4>{{this.authenticationService.mensaje.data3}}</h4>
                    <h4>{{this.authenticationService.mensaje.data4}}</h4>

                </mat-card-content>

                <mat-card-actions>
                  <p> {{this.authenticationService.mensaje.footer}} </p>
                </mat-card-actions>

            </mat-card>
            
            
            <!-- Forgot CLave -->
            <!-- <div *ngIf="!this.authenticationService.contribuyente.sesion_iniciada" class="subtitle">
                <p>
                    Se ha enviado su nueva CLAVE de acceso al correo electrónico  <b>{{email}}</b>.
                </p>
                <p>
                    Revise su bandeja de entrada o corresos no deseados, y haga clic en el enlace "SOLICITUD DE CAMBIO CLAVE SEL SAT-CAJAMARCA".
                </p>
                <p>
                    
                </p>

            </div> -->

            <!-- Reset Clave -->
            <!-- <div *ngIf="this.authenticationService.contribuyente && this.authenticationService.contribuyente.sesion_iniciada" class="subtitle">
                <p>
                    Ha cambiado su clave de acceso.
                </p>
                
                <h3>

                        Su clave es la única forma de acceder a nuestros servicios, no la comparta.
                </h3>
                
                <p>
                    
                </p>

            </div> -->


            <div class="message">
                <a class="link" [routerLink]="'/auth/login'">Regresar a Iniciar Sesión</a>
            </div>

        </div>

    </div>

</div>
