// import Swal from 'sweetalert2'
import { Component, OnInit, ViewEncapsulation, ɵConsole } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { AuthenticationService } from '@servicios/common/auth/authentication.service'
import { Router } from '@angular/router';
import { Contribuyente } from '@modelos/auth/user';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {

    hide: boolean = true
    inactivarBoton: boolean = false
    loginForm: FormGroup;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,

        private authenticationService: AuthenticationService,
        private router: Router,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            codigo: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(7)]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    login() {

        this.inactivarBoton = true

        if (this.loginForm.invalid) {
            return;
        }

        //temporal
        // if (this.fmL.codigo.value != '0000') { return }


        this.authenticationService.login(this.fmL.codigo.value, this.fmL.password.value).subscribe(

            (data: Contribuyente) => {
                this.authenticationService.contribuyente = data
            },

            (error) => {

                alert('Error de conexión: No se puede acceder al Servidor.')
                this.inactivarBoton = false
                // console.log(error)
            },

            //Complet
            () => {
                // this.router.navigate(['/menuboard']);

                // return
                //Verificamos si los datos son correctos.
                if (this.authenticationService.contribuyente ) {                   


                    if (this.authenticationService.contribuyente.cambiar_clave) {
                        this.router.navigate(['/auth/register']);
                        this.authenticationService.contribuyente.isLogin = false
                    }
                    else
                    {
                        //Accede al sistema
                        this.router.navigate(['/menuboard']);
                        this.authenticationService.contribuyente.isLogin = true

                        // console.log(this.authenticationService.contribuyente);
                    }

                }
                else {
                    //Los datos no Son correctos
                    alert('Datos Incorrectos')
                }

                this.inactivarBoton = false

            }
        )

        // let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibm9tYnJlIjoiSm9obiBEb2UiLCJpYXRlbmNpYSI6MTUxNjIzOTAyMn0.2loE0SrSIubSFPIygg66WmS7vcbXFeHj3ckR_QV-YNA'
        // token = this.authenticationService.parseJwt(token)
        // console.log(token)


    }

    register() {

        this.router.navigate(['/auth/register'])

    }

    get fmL() { return this.loginForm.controls }

}
