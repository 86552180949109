import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { ErrorDialogComponent } from '@componentes/dialog/error-dialog/error-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';

@NgModule({
    declarations: [
        ErrorDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule
    ],
    entryComponents: [
        ErrorDialogComponent
    ],
})
export class ErrorDialogModule
{
}
