import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';


import { fuseAnimations } from '@fuse/animations';

import { ScrumboardService } from '@componentes/menu-board/menu-board.service';
import { Board } from '@componentes/menu-board/board.model';

import { MatDialog,MatDialogConfig } from '@angular/material/dialog';
import { AuthenticationService } from '@servicios/common/auth/authentication.service';
// import { MarcaComponent } from '@componentes/logistica/producto/marca/marcas.component';

@Component({
    selector     : 'menu-board',
    templateUrl  :  './menu-board.component.html',
    styleUrls    : ['./menu-board.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})

export class MenuBoardComponent implements OnInit, OnDestroy
{
    boards: Board   []=[];
    
    tabSelect : number =0;


    // Private
    private _unsubscribeAll: Subject<any>;
    

  
    constructor(
        // private persistenciaScrumboardService :PersistenciaScrumboardService,
        // private buscarPersonaService : BuscarPersonaService,
        private _router: Router,
        private _scrumboardService: ScrumboardService,
        public matDialogo : MatDialog,

        private authenticationService : AuthenticationService,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.authenticationService.menuSelected = 'Menú Principal'
        this.authenticationService.menuIconSelected = 'home'
    }

   
    ngOnInit(): void
    {
        // this.buscarPersonaService.persona='contribuyente';
        // this.buscarPersonaService.conTitulo=false;
        // this.buscarPersonaService.dialogo=false;

        // if (this.persistenciaScrumboardService.contriSelect.id_contribuyente != null)
        //     this.tabSelect=2

        let b : Board;

        b = new Board({});
        b.id=3;
        b.name='Declaración Juarada Predial';
        b.descripcion = 'Detalle de Predios Declarados' ;
        b.icono='add_to_home_screen'
        b.url ='/gestion/jspredial';
        this.boards.push(b);

        b = new Board({});
         b.id=3;
         b.name='Estado Cuenta';
         b.descripcion = 'Conozca sus Deudas Pendientes de Pago' ;
         b.icono='add_to_home_screen'
         b.url ='/gestion/estadocuenta';
         this.boards.push(b);
        
        b = new Board({});
        b.id=1;
        b.name='Recibos de Pago';
        b.descripcion = 'COnsulte sus Recibos de Pago' ;
        b.icono='business'
        b.url ='/gestion/pagos';
        this.boards.push(b);
   
        // b = new Board({});
        // b.id=2;
        // b.name='Resoluciones';
        // b.icono='contacts';
        // b.url ='/producto/registro/modelo';
        // this.boards.push(b);     
       

        //  b = new Board({});
        //  b.id=3;
        //  b.name='Deudas en Cobrnaza Coactiva';
        //  b.icono='add_to_home_screen'
        //  b.url ='/producto/registro/propiedad';
        //  this.boards.push(b);
         

        //  b = new Board({});
        //  b.id=3;
        //  b.name='Solicitud';
        //  b.icono='add_to_home_screen'
        //  b.url ='/producto/registro/empaque';
        //  this.boards.push(b);

         
        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * New board
     */
    newBoard(): void
    {
        //Nuevo COntribuyente
        this._router.navigate(['/contribuyente/nuevo' ]);
       
    }

    fn_navegacion(board : Board)
    {
        // this.fn_openMarcaRegistro().subscribe();

        //Navegamos con el código de contribuyente seleccionado.
        this._router.navigate(['/'+board.url ]);        

        this.authenticationService.menuSelected = board.name
        this.authenticationService.menuIconSelected = board.icono

        

        
        // this._router.navigate(['/'+board.url+'/'+this.persistenciaScrumboardService.contriSelect.id_contribuyente.toString() ]);        
    }

    // fn_openMarcaRegistro(): Observable < any > {
    //     const dialogConfig = new MatDialogConfig();

    //     dialogConfig.autoFocus=true;
    //     dialogConfig.disableClose=true;
    //     // dialogConfig.hasBackdrop=true;
    //     dialogConfig.closeOnNavigation=true;
    //     dialogConfig.height = "95%";
    //     dialogConfig.width ="75%"
        



    //     // dialogConfig.data ={
    //     //     id :1,
    //     //     title : 'NUEVA MARCA'
    //     // }



    //     const dialogo = this.matDialogo.open(MarcaComponent, dialogConfig);
      
    //     return dialogo.afterClosed();
    //   }

}
