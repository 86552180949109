import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector   : 'done-dialog',
    templateUrl: './done-dialog.component.html',
    styleUrls  : ['./done-dialog.component.scss']
})
export class DoneDialogComponent
{
    public confirmMessage: string;
    public titleMessage : string

    /**
     * Constructor
     *
     * @param {MatDialogRef<DoneDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<DoneDialogComponent>
    )
    {
    }

}
